import Vue from 'vue'
import sumBy from 'lodash/sumBy'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import { mapState } from 'vuex'
import { RootState } from '@/store'
import { formatUsd, nFormatter } from '@/utils/moneyFormat'
import { StatisticTableColumn } from '@/components/StatisticsTable/StatisticsTable'
import SalaryStatistic from '@/models-ts/salaryStatistic/SalaryStatistic'
import { ChartOptions } from 'chart.js'

export default Vue.extend<any, any, any, any>({
  computed: {
    ...mapState<RootState>({
      locations: (state: RootState) => state.salary.salaryData.value.locations,
    }),
    columns (): StatisticTableColumn[] {
      const columns = [
        { name: 'position', title: 'Location' },
        { name: 'avg', title: `Avg. ${this.isTabletMax ? '' : 'annual '}salary`, align: 'right' },
        { name: 'min', title: `Min. ${this.isTabletMax ? '' : 'annual '}salary`, align: 'right' },
        { name: 'max', title: `Max. ${this.isTabletMax ? '' : 'annual '}salary`, align: 'right' },
      ]
      // @ts-ignore
      return columns.filter(i => i != null)
    },
    rows () {
      return this.locations.map((loc: SalaryStatistic, id: number) => ({
        ...loc,
        id,
        min: `$ ${formatUsd(loc.min)}`,
        avg: `$ ${formatUsd(loc.avg)}`,
        max: `$ ${formatUsd(loc.max)}`,
      }))
    },
    chartData () {
      return this.locations.map((loc: SalaryStatistic) => ({ title: loc.position, value: loc.avg }))
    },
    chartOptions () {
      return {
        plugins: {
          tooltip: {
            displayColors: false,
            callbacks: {
              label: (item) => `$${formatUsd(item.raw as string)}`
            }
          }
        },
        scales: {
          yAxes: {
            ticks: {
              callback: (value: string) => nFormatter(value, 0)
            }
          }
        }
      } as ChartOptions
    },
    avgSalary () {
      return formatUsd(Math.floor(sumBy(this.locations, (loc: SalaryStatistic) => loc.avg) / this.locations.length))
    },
    highestSalary () {
      const loc = maxBy(this.locations, (loc: SalaryStatistic) => loc.max)
      if (!loc) {
        return { min: 0, avg: 0, max: 0 }
      }
      return { min: nFormatter(loc.min), avg: nFormatter(loc!.avg), max: nFormatter(loc!.max) }
    },
    lowestSalary () {
      const loc = minBy(this.locations, (loc: SalaryStatistic) => loc.min)
      if (!loc) {
        return { min: 0, avg: 0, max: 0 }
      }
      return { min: nFormatter(loc.min), avg: nFormatter(loc.avg), max: nFormatter(loc.max) }
    }
  },
})
