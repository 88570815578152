import Vue from 'vue'
import { VACANCIES } from '@/constants/routes'

export default Vue.extend<any, any, any, any>({
  data () {
    return {
      VACANCIES,
    }
  },
})
