import Vue from 'vue'
import { mapState } from 'vuex'
import { RootState } from '@/store'
import { StatisticTableColumn } from '@/components/StatisticsTable/StatisticsTable'
import { formatUsd } from '@/utils/moneyFormat'
import SalaryStatistic from '@/models-ts/salaryStatistic/SalaryStatistic'
import SeniorityChart from './SeniorityChart/SeniorityChart.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    SeniorityChart,
  },
  computed: {
    ...mapState<RootState>({
      seniority: (state: RootState) => state.salary.salaryData.value.seniority,
    }),
    columns (): StatisticTableColumn[] {
      const columns = [
        { name: 'position', title: 'Position' },
        { name: 'avg', title: `Avg. ${this.isTabletMax ? '' : 'annual '}salary`, align: 'right' },
        { name: 'min', title: `Min. ${this.isTabletMax ? '' : 'annual '}salary`, align: 'right' },
        { name: 'max', title: `Max. ${this.isTabletMax ? '' : 'annual '}salary`, align: 'right' },
      ]
      // @ts-ignore
      return columns.filter(i => i != null)
    },
    rows () {
      return this.seniority.map((sen: SalaryStatistic, id: number) => ({
        ...sen,
        id,
        position: `${sen.position} Developer`,
        min: `$ ${formatUsd(sen.min)}`,
        avg: `$ ${formatUsd(sen.avg)}`,
        max: `$ ${formatUsd(sen.max)}`,
      }))
    },
  },
})
