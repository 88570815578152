import Vue from 'vue'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import { mapState } from 'vuex'
import { RootState } from '@/store'
import { StatisticTableColumn } from '@/components/StatisticsTable/StatisticsTable'
import { formatUsd, nFormatter } from '@/utils/moneyFormat'
import SalaryStatistic from '@/models-ts/salaryStatistic/SalaryStatistic'
import { ChartOptions } from 'chart.js'

const SORT_OPTIONS = [
  {
    name: 'Avg. salary',
    value: 'avg_desc',
    field: 'avg',
    dir: 'desc',
  },
  {
    name: 'Avg. salary',
    value: 'avg_asc',
    field: 'avg',
    dir: 'asc',
  },
]

interface TableRow {
  id: number,
  position: string
  base: number
  avg: number
  max: number
  baseFormatted: string
  avgFormatted: string
  maxFormatted: string
}

export default Vue.extend<any, any, any, any>({
  data () {
    return {
      loading: false,
      sortValue: null,
      sortOptions: SORT_OPTIONS,
      more: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      salaries: (state: RootState) => state.salary.salaryData.value.salaries,
    }),
    columns () {
      const posWidth = this.isTabletMax ? '30%' : '21.7%'
      const columns: Array<StatisticTableColumn | null> = [
        { name: 'position', title: 'Position', width: this.xsAndDown ? '50%' : posWidth },
        { name: 'baseFormatted', title: `Base ${this.isTabletMax ? '' : 'annual '}salary`, align: 'right' },
        { name: 'avgFormatted', title: `Avg. ${this.isTabletMax ? '' : 'annual '}salary`, align: 'right' },
        { name: 'maxFormatted', title: `Max. ${this.isTabletMax ? '' : 'annual '}salary`, align: 'right' },
      ]

      return columns.filter(i => i !== null)
    },
    rows () {
      const rows: TableRow[] = this.salaries.map((sal: SalaryStatistic, id: number) => ({
        id,
        position: sal.position,
        base: sal.min,
        avg: sal.avg,
        max: sal.max,
        baseFormatted: `$ ${formatUsd(sal.min)}`,
        avgFormatted: `$ ${formatUsd(sal.avg)}`,
        maxFormatted: `$ ${formatUsd(sal.max)}`,
      }))

      if (this.sortValue) {
        rows.sort((a, b) => {
          const field = this.sortValue.field as keyof TableRow
          if (this.sortValue.dir === 'asc') {
            return a[field] > b[field] ? 1 : -1
          }
          return a[field] < b[field] ? 1 : -1
        })
      }

      return this.more ? rows : rows.slice(0, 10)
    },
    chartData () {
      return this.salaries.map((sal: SalaryStatistic) => ({ title: sal.position, value: sal.avg }))
    },
    chartOptions () {
      return {
        plugins: {
          tooltip: {
            displayColors: false,
            callbacks: {
              label: (item) => `$${formatUsd(item.raw as string)}`
            }
          }
        },
        scales: {
          yAxes: {
            ticks: {
              callback: (value: string) => nFormatter(value, 0)
            }
          }
        }
      } as ChartOptions
    },
    highestSalary () {
      const sal = maxBy(this.salaries, (sal: SalaryStatistic) => sal.max)
      if (!sal) {
        return {
          base: 0,
          avg: 0,
          max: 0
        }
      }
      return { base: formatUsd(sal.min), avg: formatUsd(sal.avg), max: formatUsd(sal.max) }
    },
    lowestSalary () {
      const sal = minBy(this.salaries, (sal: SalaryStatistic) => sal.min)
      if (!sal) {
        return {
          base: 0,
          avg: 0,
          max: 0
        }
      }
      return { base: formatUsd(sal.min), avg: formatUsd(sal.avg), max: formatUsd(sal.max) }
    }
  },
  methods: {
    async onSortInput (sort: any) {
      this.sortValue = SORT_OPTIONS.find(s => s.value === sort.value)
    },
  },
})
