import Vue from 'vue'
import { formatUsd } from '@/utils/moneyFormat'

export default Vue.extend<any, any, any, any>({
  props: {
    level: Object,
    totalMax: Number,
  },
  data () {
    return {
      loading: false,
      more: false,
    }
  },
  computed: {
    min () {
      return formatUsd(this.level.min)
    },
    max () {
      return formatUsd(this.level.max)
    },
    avg () {
      return formatUsd(Math.floor((this.level.min + this.level.max) / 2))
    },
    heightMin () {
      return `${this.level.min * 128 / this.totalMax}px`
    },
    heightMax () {
      return `${this.level.max * 128 / this.totalMax}px`
    },
  },
})
