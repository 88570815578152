import Vue from 'vue'
import { mapState } from 'vuex'
import { RootState } from '@/store'
import canonicalMixin from '@/mixins/canonicalMixin'
import { formatDate } from '@/utils/date'
import { sumBy } from 'lodash'
import { formatUsd, nFormatter } from '@/utils/moneyFormat'
import DateAmountStatistic from '@/models-ts/salaryStatistic/DateAmountStatistic'
import { ChartOptions } from 'chart.js'

export default Vue.extend<any, any, any, any>({
  computed: {
    ...mapState<RootState>({
      applications: (state: RootState) => state.salary.salaryData.value.applications,
    }),
    metaTitle () {
      return `Web3 & Blockchain Developers Salaries - ${formatDate(new Date(), 'MMMM YYYY')}`
    },
    metaDescription () {
      // eslint-disable-next-line max-len
      return `★ Discover the average Web3 & Blockchain Developers salary (/yr) and explore in-depth blockchain salary data. Compare pay by job title, region (US, Asia, Europe, remote) for this booming industry. ✔`
    },
    chartData () {
      return this.applications.map((app: DateAmountStatistic) => ({ title: app.date, value: app.value }))
    },
    chartOptions () {
      return {
        plugins: {
          tooltip: {
            displayColors: false,
            callbacks: {
              label: (item) => formatUsd(item.raw as string)
            }
          }
        },
        scales: {
          yAxes: {
            ticks: {
              callback: (value: string) => nFormatter(value, 1)
            }
          }
        }
      } as ChartOptions
    },
    avgJobs () {
      return formatUsd(Math.floor(sumBy(this.applications, (app: DateAmountStatistic) => app.value) / this.applications.length))
    },
  },
  methods: {
    formatUsd,
  }
})
