import { render, staticRenderFns } from "./StatisticsBySeniority.htm?vue&type=template&id=1b2bc8b3&scoped=true&"
import script from "./StatisticsBySeniority.ts?vue&type=script&lang=js&"
export * from "./StatisticsBySeniority.ts?vue&type=script&lang=js&"
import style0 from "./StatisticsBySeniority.scss?vue&type=style&index=0&id=1b2bc8b3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b2bc8b3",
  null
  
)

export default component.exports