import Vue from 'vue'
import { mapState } from 'vuex'
import { RootState } from '@/store'
import { sumBy } from 'lodash'
import { formatUsd, nFormatter } from '@/utils/moneyFormat'
import DateAmountStatistic from '@/models-ts/salaryStatistic/DateAmountStatistic'
import { ChartOptions } from 'chart.js'

export default Vue.extend<any, any, any, any>({
  computed: {
    ...mapState<RootState>({
      trends: (state: RootState) => state.salary.salaryData.value.trends,
    }),
    chartData () {
      return this.trends.map((trend: DateAmountStatistic) => ({ ...trend, title: trend.date }))
    },
    chartOptions () {
      return {
        plugins: {
          tooltip: {
            displayColors: false,
            callbacks: {
              label: (item) => `$${formatUsd(item.raw as string)}`
            }
          }
        },
        scales: {
          yAxes: {
            ticks: {
              callback: (value: string) => nFormatter(value, 0)
            }
          }
        }
      } as ChartOptions
    },
    avgSalary () {
      return formatUsd(Math.floor(sumBy(this.trends, (trend: DateAmountStatistic) => trend.value) / this.trends.length))
    },
    dateChanged () {
      return `How Web3 salary has changed in ${this.trends[0].date} - ${this.trends[this.trends.length - 1].date}:`
    },
  },
  methods: {
    nFormatter,
  }
})
