import Vue from 'vue'
import { mapState } from 'vuex'
import { RootState } from '@/store'
import { sumBy } from 'lodash'
import { formatUsd, nFormatter } from '@/utils/moneyFormat'
import DateAmountStatistic from '@/models-ts/salaryStatistic/DateAmountStatistic'
import { ChartOptions } from 'chart.js'

export default Vue.extend<any, any, any, any>({
  data () {
    return {
      loading: false,
      more: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      jobs: (state: RootState) => state.salary.salaryData.value.jobs,
    }),
    chartData () {
      return this.jobs.map((jb: DateAmountStatistic) => ({ title: jb.date, value: jb.value }))
    },
    chartOptions () {
      return {
        plugins: {
          tooltip: {
            displayColors: false,
            callbacks: {
              label: (item) => formatUsd(item.raw as string)
            }
          }
        },
        scales: {
          yAxes: {
            ticks: {
              callback: (value: string) => nFormatter(value, 0)
            }
          }
        }
      } as ChartOptions
    },
    avgJobs () {
      return formatUsd(Math.floor(sumBy(this.jobs, (item: DateAmountStatistic) => item.value) / this.jobs.length))
    },
    dateChanged () {
      /* eslint-disable max-len */
      return this.jobs.length > 1
        ? `How the number of Web3 & Blockchain Developers Jobs has changed in ${this.jobs[0].date} - ${this.jobs[this.jobs.length - 1].date}:`
        : 'How the number of Web3 & Blockchain Developers Jobs has changed'
    },
  },
  methods: {
    formatUsd,
  }
})
