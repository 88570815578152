import Vue from 'vue'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import { mapActions, mapMutations, mapState } from 'vuex'
import { RootState } from '@/store'
import canonicalMixin from '@/mixins/canonicalMixin'
import responseMixin from '@/mixins/responseMixin'
import { formatDate } from '@/utils/date'
import StatisticsByRole from './StatisticsByRole/StatisticsByRole.vue'
import StatisticsConclusions from './StatisticsConclusions/StatisticsConclusions.vue'
import StatisticsByTrend from './StatisticsByTrend/StatisticsByTrend.vue'
import StatisticsByJobs from './StatisticsByJobs/StatisticsByJobs.vue'
import StatisticsByApplicants from './StatisticsByApplicants/StatisticsByApplicants.vue'
import StatisticsBySeniority from './StatisticsBySeniority/StatisticsBySeniority.vue'
import StatisticsByLocation from './StatisticsByLocation/StatisticsByLocation.vue'
import FindJobsSection from './FindJobsSection/FindJobsSection.vue'
import SalaryStatistic from '@/models-ts/salaryStatistic/SalaryStatistic'
import { nFormatter } from '@/utils/moneyFormat'

export default Vue.extend<any, any, any, any>({
  mixins: [canonicalMixin, responseMixin],
  components: {
    StatisticsConclusions,
    StatisticsByRole,
    StatisticsByTrend,
    StatisticsByJobs,
    StatisticsByApplicants,
    StatisticsBySeniority,
    StatisticsByLocation,
    FindJobsSection,
  },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      prefetched: (state: RootState) => state.salary.prefetched,
      isLoaded: (state: RootState) => state.salary.salaryData.isLoaded,
      salaries: (state: RootState) => state.salary.salaryData.value.salaries,
    }),
    highestSalary () {
      const sal = maxBy(this.salaries, (sal: SalaryStatistic) => sal.max)
      if (!sal) {
        return 0
      }
      return nFormatter(sal.max)
    },
    lowestSalary () {
      const sal = maxBy(this.salaries, (sal: SalaryStatistic) => sal.min)
      if (!sal) {
        return 0
      }
      const formatted = nFormatter(sal.min)
      const lastSymbolH = this.highestSalary[this.highestSalary.length - 1]
      const lastSymbolL = formatted[formatted.length - 1]
      if (lastSymbolL === lastSymbolH) {
        return formatted.slice(0, -1)
      }
      return formatted
    },
    metaTitle () {
      return `Web3 & Blockchain Developers Salaries - ${formatDate(new Date(), 'MMMM YYYY')}`
    },
    metaDescription () {
      // eslint-disable-next-line max-len
      return `★ Discover the average Web3 & Blockchain Developers salary (${this.lowestSalary}-${this.highestSalary}/yr) and explore in-depth blockchain salary data. Compare pay by job title, region (US, Asia, Europe, remote) for this booming industry. ✔`
    },
  },
  async prefetch () {
    if (process.server) {
      await this.getSalaryStatisticsPage()
      this.setPrefetched(true)
    }
  },
  async created () {
    if (process.client) {
      try {
        if (!this.prefetched) {
          await this.getSalaryStatisticsPage()
        }
        this.setPrefetched(false)
      } catch (e) {
        this.parseError(e)
      }
    }
  },
  methods: {
    ...mapMutations({
      setPrefetched: 'salary/setPrefetched',
    }),
    ...mapActions({
      getSalaryStatisticsPage: 'salary/getSalaryStatisticsPage',
    })
  },
  metaInfo () {
    return {
      title: this.metaTitle,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.metaDescription,
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.metaTitle,
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.metaDescription,
        },
      ],
      link: [this.canonicalLink],
    }
  },
})
