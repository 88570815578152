import Vue from 'vue'
import LevelBar from './LevelBar/LevelBar.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    LevelBar,
  },
  props: ['levels'],
  computed: {
    totalMax () {
      return Math.max(...(this.levels.map((l: any) => l.max)))
    },
  },
})
