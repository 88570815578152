import Vue from 'vue'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import { mapState } from 'vuex'
import { RootState } from '@/store'
import { formatUsd } from '@/utils/moneyFormat'
import SalaryStatistic from '@/models-ts/salaryStatistic/SalaryStatistic'

export default Vue.extend<any, any, any, any>({
  computed: {
    ...mapState<RootState>({
      salaries: (state: RootState) => state.salary.salaryData.value.salaries,
    }),
    avgMin () {
      return formatUsd(minBy(this.salaries, (sal: SalaryStatistic) => sal.avg)!.avg)
    },
    avgMax () {
      return formatUsd(maxBy(this.salaries, (sal: SalaryStatistic) => sal.avg)!.avg)
    },
    conclusionSalaries () {
      return this.salaries
        .filter((sal: SalaryStatistic) => sal.isConclusion)
        .map((sal: SalaryStatistic) => ({
          position: sal.position,
          min: formatUsd(sal.min),
          max: formatUsd(sal.max),
        }))
    },
  },
})
